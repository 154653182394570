import React, {useEffect, useState} from "react";
import BaseScreen from "../base/BaseScreen";
import styled from "styled-components";
import backLogo from "../../resources/images/back.svg";
import '../datepicker.scss';
import {device} from "../../constants/breakpoints";
import Api from "../../util/Api";
import OverViewCard from "./OverviewCard";
import {useHistory} from "react-router-dom";
import {PlanOfActionTabs} from "../../constants/enums";
import QuestionsListCard from "./QuestionsListCard";
import AppStorage, {StorageKey} from "../../util/AppStorage";
import forwardIcon from "../../resources/images/forwardWhite.svg"

import {
    BackgroundedScreen,
    Header,
    HeaderLogo,
    HeaderTitle,
    nunito,
    purple,
    purpleLighter,
    red,
    sourceSansPro,
    white
} from "../../Theme";
import {Loader} from "../../components/Loader";
import {useGlobalState} from "../../util/GlobalState";
import {showNativeNavigation} from "../../AppInterface";

function PlanOfActionScreen(props) {
    const history = useHistory();
    const [period, setPeriod] = useState(history.location.state ? history.location.state.period : null)
    const [answers, setAnswers] = useState(null);
    const [activeTab, setActiveTab] = useState(PlanOfActionTabs.EF)
    const [isNative] = useGlobalState('isNative', false)

    useEffect(() => {
        showNativeNavigation();
        getPeriod()
        getAnswers()
        // eslint-disable-next-line
    }, []);

    const content = (
        <BackgroundedScreen>
            {!period && !answers &&
                <Loader/>
            }

            {period && answers &&
                <HeaderListFooterWrapper>
                    <HeaderAndListWrapper>
                        <Header>
                            <HeaderLogo src={backLogo} onClick={() => {
                                history.goBack();
                            }}/>
                            <HeaderTitle>{period && period.title}</HeaderTitle>
                            <HeaderLogo visibility={"hidden"}/>
                        </Header>
                        <TabWrapper>
                            <Tab isActive={activeTab === PlanOfActionTabs.EF}
                                 onClick={() => setActiveTab(PlanOfActionTabs.EF)}>
                                {PlanOfActionTabs.EF}
                            </Tab>
                            <Tab isActive={activeTab === PlanOfActionTabs.PLANOFACTION}
                                 onClick={() => setActiveTab(PlanOfActionTabs.PLANOFACTION)}>
                                {PlanOfActionTabs.PLANOFACTION}
                            </Tab>
                            <Tab isActive={activeTab === PlanOfActionTabs.EVALUATION}
                                 onClick={() => setActiveTab(PlanOfActionTabs.EVALUATION)}>
                                {PlanOfActionTabs.EVALUATION}
                            </Tab>
                        </TabWrapper>
                        <ContentCard>
                            <OverViewCard period={period} activeTab={activeTab}/>
                            <QuestionsListCard
                                period={period}
                                answers={answers}
                                activeTab={activeTab}
                                type={PlanOfActionTabs.PLANOFACTION}
                                onSave={(answerData) => {
                                    saveAnswer(answerData)
                                }}
                            />
                            <QuestionsListCard
                                period={period}
                                answers={answers}
                                activeTab={activeTab}
                                type={PlanOfActionTabs.EVALUATION}
                                onSave={(answerData) => {
                                    saveAnswer(answerData)
                                }}
                            />
                        </ContentCard>
                        <DesktopFooter/>
                    </HeaderAndListWrapper>
                    <TabletFooter/>
                </HeaderListFooterWrapper>
            }
        </BackgroundedScreen>
    );

    return (
        <BaseScreen
            activeMenuItem={"Plan van aanpak"}
            content={content}
        />
    )

    function DesktopFooter() {
        return <DesktopContentCardFooter isNative={isNative}>
            <FooterContent/>
        </DesktopContentCardFooter>
    }

    function TabletFooter() {
        return <TabletContentCardFooter isNative={isNative}>
            <FooterContent/>
        </TabletContentCardFooter>
    }

    function FooterContent() {
        return <React.Fragment>
            <QuestionnaireButton onClick={() => history.push("/plan-van-aanpak/" + props.match.params.id + "/vragenlijst", {periodId: props.match.params.id})}>
                <QuestionnaireButtonText>Bekijk ingevulde vragenlijst</QuestionnaireButtonText>
                <QuestionnaireButtonIcon src={forwardIcon} alt={"Pijltje naar rechts"}/>
            </QuestionnaireButton>
        </React.Fragment>
    }

    function getPeriod() {
        const config = {
            params: {
                'context[personId]': AppStorage.get(StorageKey.PUPIL_ID) //call contains information generated on logged in member or context person
            }
        }

        Api.get('periods/' + props.match.params.id, onSuccess, null, null ,null, config);

        function onSuccess(response) {
            setPeriod(response.data)
        }
    }

    function getAnswers() {
        const config = {
            params: {
                'filter[periodId]': props.match.params.id,
            }
        }

        Api.get("/pupils/" + AppStorage.get(StorageKey.PUPIL_ID) + "/answers", (response) => {
            setAnswers(response.data);
        }, null, null, null, config);
    }

    function saveAnswer(answerData){
        const config = {
            params: {
                'filter[periodId]': props.match.params.id,
            }
        }

        Api.put("/pupils/" + AppStorage.get(StorageKey.PUPIL_ID) + "/answers/" + (answerData.id ? answerData.id : ""), answerData, null,null, null, null, config);
    }
}

export function SaveButton(props) {
    return (
        <SaveButtonRoot onClick={props.onClick}>
            <SaveButtonText>Opslaan</SaveButtonText>
        </SaveButtonRoot>
    );
}


export const Tab = styled.div`
    background: ${props => props.isActive ? red : 'transparent'};
    color: ${props => props.isActive ? white : purple};
    font-weight: ${props => props.isActive ? 'bold' : 'normal'};
    font-size: 16px;
    font-family: ${nunito};
    padding: 4px;
    border-radius: 5px;
    cursor: pointer;
`;

export const TabWrapper = styled.div`
    background: rgba(94, 65, 135, 0.07);
    height: 50px;
    width: 100%;
    border-radius: 3px;
    display: flex;
    margin-bottom: 10px;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 9px;
    padding-right: 9px;
    flex-direction: row;
    align-items: center;
    gap: 15px;
`;

export const HeaderListFooterWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const HeaderAndListWrapper = styled.div`
    width: 100%;
    max-width: 590px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 20px 16px 54px 16px;
    
    @media screen and ${device.tablet} {
        padding-bottom: 114px;
        flex: 1 1 0;
        overflow-y: auto;
    }

    @media screen and ${device.mobileL} {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 84px;
        box-sizing: border-box;
    }
`;

export const ContentCard = styled.div`
    width: 100%;
    background: ${white};
    display: flex;
    border: 1px solid rgba(86, 89, 146, 0.1);
    box-shadow: 0px 2px 0px rgba(86, 89, 146, 0.1);
    border-radius: 3px 3px 0 0;
    padding: 38px 67px;
    
    @media screen and ${device.tablet} {
        padding: 38px 47px;
    }
    
    @media screen and ${device.mobileL} {
        padding: 20px 23px;
    }
`;

export const DesktopContentCardFooter = styled.div`
    width: 100%;
    height: 60px;
    background: ${purpleLighter};
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 0px rgba(86, 89, 146, 0.1);
    border-radius: 0px 0px 3px 3px;
    
    @media screen and ${device.tablet} {
        display: none;
    }
`;

export const TabletContentCardFooter = styled.div`
    display: none;
    
    @media screen and ${device.tablet} {
        width: 100%;
        height: 60px;
        background: ${purpleLighter};
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 2px 0px rgba(86, 89, 146, 0.1);
        border-radius: 0px 0px 3px 3px;
        flex: 0 0 auto;
    }
`;

export const QuestionnaireButton = styled.div`
    max-width: 313px;
    width: 100%;
    height: 37px;
    background: ${purple};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    box-shadow: 0px 2px 0px #4D2F78;
    border-radius: 3px;
    cursor: pointer;
`;

export const QuestionnaireButtonText = styled.div`
    font-family: ${sourceSansPro};
    font-weight: 600;
    font-size: 15px;
    color: ${white};
`;

export const QuestionnaireButtonIcon = styled.img`
    width: 17px;
`;

export const SaveButtonRoot = styled.div`
    width: 125px;
    height: 35px;
    background: ${purple};
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 0px #4D2F78;
    border-radius: 3px;
    align-self: flex-end;
    cursor: pointer;
`;

export const SaveButtonText = styled.div`
    font-family: ${sourceSansPro};
    font-weight: 600;
    font-size: 15px;
    color: ${white};
`;

export default PlanOfActionScreen;
