import GlobalLoader from "../GlobalLoader";
import loaderImage from "../resources/images/loader.svg";
import {nunito, purple} from "../Theme";

export const Loader = () => {
    return (
        <GlobalLoader
            image={loaderImage}
            imageWidth={"80px"}
            imageWidthMobile={"40px"}
            text={"Laden..."}
            textFont={nunito}
            textSize={"21px"}
            textSizeMobile={"15px"}
            textWeight={"600"}
            textColor={purple}
            textImageGap={"10px"}
            textImageGapMobile={"5px"}
            delay={true}
        />
    )
};