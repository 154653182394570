import {useEffect, useState} from "react";
import styled from "styled-components";
import {nunito, purpleDark, red} from "../Theme";
import calenderLogo from "../resources/images/calendar.svg";
import chartLogo from "../resources/images/chart.svg";
import bulbLogo from "../resources/images/bulb.svg";
import scienceLogo from "../resources/images/science.svg";
import moreLogo from "../resources/images/more.svg";
import {Link, useLocation} from "react-router-dom";
import {RoutesWithoutNav, useQuery} from "../App";
import {useGlobalState} from "../util/GlobalState";
import {ShowTaskPopup} from "../popups/showTaskPopup";
import {BasicPopup} from "../popups/showBasicPopup";
import {ShowExecutiveFunctionProgressPopup} from "../popups/showExecutiveFunctionProgressPopup";
import {ShowImagePopup} from "../popups/showImagePopup";
import {OnboardingPopup} from "../popups/showOnboardingPopup";
import {TasksFinishedPopup} from "../popups/showTasksFinishedPopup";
import {WhatsNewPopup} from "../popups/whatsNewPopup";

function BottomMenu() {

    const location = useLocation();
    const [currentLocation, setCurrentLocation] = useState(location.pathname);
    const [isVisible, setVisible] = useState(false);
    const [isDashboard, setIsDashboard] = useGlobalState('isDashboard', false);
    const [isNative, setNative] = useGlobalState('isNative', false)
    const query = useQuery();

    useEffect(() => {
        if (window.location.href.indexOf("dashboard") > -1) {
            setIsDashboard(true)
        } else {
            setIsDashboard(false)
        }
    })

    useEffect(() => {
        if (RoutesWithoutNav.includes(location.pathname)) {
            setVisible(false);
        } else {
            setVisible(true);
        }
        setCurrentLocation(location.pathname);

        closePopupIfActive()

        // eslint-disable-next-line
    }, [location.pathname]);

    // set ref so native apps can hide the bottomMenu through the AppInterface
    useEffect(() => {
        if (query.get("app")) {
            setNative(true)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <BottomMenuWrapper $visible={isVisible && !isNative && !isDashboard}>
            <MenuItemList>
                <MenuItem src={calenderLogo} text={"Planagenda"} isActive={currentLocation === "/"} to={"/"}
                          location={location}/>
                <MenuItem src={chartLogo} text={"Plan aanpak"} isActive={currentLocation === "/plan-van-aanpak"}
                          to={"/plan-van-aanpak"} location={location}/>
                <MenuItem src={scienceLogo} text={"Toetsweek"} isActive={currentLocation === "/toetsweek"}
                          to={"/toetsweek"} location={location}/>
                <MenuItem src={bulbLogo} text={"EF's"} isActive={currentLocation === "/executieve-functies"}
                          to={"/executieve-functies"} location={location}/>
                <MenuItem src={moreLogo} text={"Meer"} isActive={currentLocation.includes("/meer")} to={"/meer"}
                          location={location}/>
            </MenuItemList>
        </BottomMenuWrapper>
    )

    function closePopupIfActive() {
        const popups = [
            BasicPopup,
            ShowExecutiveFunctionProgressPopup,
            ShowImagePopup,
            OnboardingPopup,
            ShowTaskPopup,
            TasksFinishedPopup,
            WhatsNewPopup
        ]

        popups.forEach(popup => {
            if (!!popup) {
                popup.close()
            }
        })
    }
}

function MenuItem(props) {
    return (
        <MenuItemWrapper to={props.to} $isActive={props.isActive}>
            <MenuItemLogo src={props.src}/>
            <MenuItemText> {props.text}</MenuItemText>
        </MenuItemWrapper>
    );
}

const BottomMenuWrapper = styled.div`
    background: ${purpleDark};
    width: 100%;
    height: 60px;
    display: ${props => props.$visible ? "flex" : "none"};
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 1000;
`;

const MenuItemList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;
`;

const MenuItemWrapper = styled(Link)`
    text-decoration: none;
    background: ${props => props.$isActive ? red : undefined};
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 48px;
    width: 64px;
`

const MenuItemLogo = styled.img`
    color: white;
    display: block;
    height: 24px;
    width: 24px;
    margin-top: 3px;
    src: ${props => props.src};
`

const MenuItemText = styled.h1`
    color: white;
    font-family: ${nunito};
    font-style: normal;
    font-size: 10px;
`

export default BottomMenu;