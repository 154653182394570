import {useEffect, useState} from "react";
import styled from "styled-components";
import {nunito, purpleDark, red, white} from "../Theme";
import gridLogo from "../resources/images/grid.svg";
import profileLogo from "../resources/images/pupil_profile.svg";
import chevronLeftLogo from "../resources/images/chevron_left.svg";
import calenderLogo from "../resources/images/calendar.svg";
import chartLogo from "../resources/images/chart.svg";
import bulbLogo from "../resources/images/bulb.svg";
import scienceLogo from "../resources/images/science.svg";
import moreLogo from "../resources/images/more.svg";
import logoutLogo from "../resources/images/logout.svg";
import opnijLogo from "../resources/images/logo.svg";
import caretUp from "../resources/images/ic-caretUp.svg";
import caretDown from "../resources/images/ic-caretDown.svg";
import menuBackground from "../resources/images/menuBackground.svg";
import AppStorage, {StorageKey} from "../util/AppStorage";
import Api from "../util/Api";
import {Link, useHistory, useLocation} from "react-router-dom";
import {isInReadOnlyMode, RoutesWithoutNav} from "../App";
import {UserType} from "../constants/enums";
import {useGlobalState} from "../util/GlobalState";

function SideMenu() {

    const history = useHistory();
    const location = useLocation();
    const [currentLocation, setCurrentLocation] = useState(location.pathname);
    const [morePages, setMorePages] = useState([]);
    const [isDashboard, setIsDashboard] = useGlobalState('isDashboard', false);
    const [isVisible, setVisible] = useState(false);

    useEffect(() => {
        if (isVisible) {
            Api.get("/more", (response) => {
                setMorePages(response.data);
            })
        }
        // eslint-disable-next-line
    }, [isVisible]);

    useEffect(() => {
        const userType = AppStorage.get(StorageKey.USER_TYPE);
        const pupilID = AppStorage.get(StorageKey.PUPIL_ID);
        if (userType === UserType.MENTOR && pupilID === null) {
            history.push("/dashboard");
        }
        if (userType === UserType.PUPIL && location.pathname.includes("/dashboard")) {
            history.push("/");
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (RoutesWithoutNav.includes(location.pathname)) {
            setVisible(false);
        } else {
            setVisible(true);
        }
        setCurrentLocation(location.pathname);
        // eslint-disable-next-line
    }, [location.pathname]);

    useEffect(() => {
        if (window.location.href.indexOf("dashboard") > -1) {
            setIsDashboard(true)
        } else {
            setIsDashboard(false)
        }
    })

    return (
        <SideMenuWrapper $visible={isVisible}>
            <MenuBackground src={menuBackground}/>
            <MenuLogo src={opnijLogo}/>
            { isInReadOnlyMode() && !isDashboard &&
                <>
                    <MenuItemList>
                        <MenuItemLight backToDashboard={true} size={"14px"} paddingBottom={"20px"} src={chevronLeftLogo} menuClick={() => {clearPupil()}} text={"Mentor dashboard"} to={"/dashboard"}/>
                    </MenuItemList>
                    <MenuItemList key={0}>
                        <MenuPerson disableClick={true} src={profileLogo} to={"/"} text={AppStorage.get(StorageKey.PUPIL_TITLE)}/>
                        <MenuItemListSeparator key={1}/>
                    </MenuItemList>
                </>

            }
            { isDashboard ?
                <MenuItemList>
                    <MenuItem src={gridLogo} text={"Mentor dashboard"} isActive={currentLocation === "/dashboard"} to={"/dashboard"}
                              location={location}/>
                </MenuItemList> :
                <MenuItemList>
                    <MenuItem src={calenderLogo} text={"Planagenda"} isActive={currentLocation === "/"} to={"/"}
                              location={location}/>
                    <MenuItem src={chartLogo} text={"Plan van aanpak"}
                              isActive={currentLocation.includes("/plan-van-aanpak")} to={"/plan-van-aanpak"}
                              location={location}/>
                    <MenuItem src={scienceLogo} text={"Toetsweekplanning"} isActive={currentLocation === "/toetsweek"}
                              to={"/toetsweek"} location={location}/>
                    <MenuItem src={bulbLogo} text={"Executieve functies"}
                              isActive={currentLocation === "/executieve-functies"} to={"/executieve-functies"}
                              location={location}/>
                    <ExtendableMenuItem src={moreLogo} text={"Meer"} items={morePages}/>
                </MenuItemList>
            }

            <MenuItemList>
                <LogOutButton src={logoutLogo} text={"Uitloggen"} to={"/login"} onClick={() => {
                    logoutAndGoToLogin(history)
                }}/>
            </MenuItemList>
        </SideMenuWrapper>
    )
}

export function loginAndContinueToNextScreen(data, history) {
    AppStorage.set(StorageKey.API_ACCESS_TOKEN, data.accessToken)
    AppStorage.set(StorageKey.USER_ID, data.id)
    AppStorage.set(StorageKey.FINISHED_ONBOARDING, data.hasFinishedOnboarding)
    AppStorage.set(StorageKey.USER_TYPE, data.type)
    if (data.type === UserType.PUPIL) {
        //login in as pupil
        AppStorage.set(StorageKey.PUPIL_ID, data.id)
    }

    const userType = AppStorage.get(StorageKey.USER_TYPE)
    if (userType === UserType.PUPIL) {
        history.replace("/");
    } else if (userType === UserType.MENTOR) {
        history.replace("/dashboard");
    }
}

export function logoutAndGoToLogin(history) {
    AppStorage.remove(StorageKey.USER_ID)
    AppStorage.remove(StorageKey.USER_TYPE)
    AppStorage.remove(StorageKey.API_ACCESS_TOKEN)
    AppStorage.remove(StorageKey.FINISHED_ONBOARDING)
    AppStorage.remove(StorageKey.PUPIL_ID)
    AppStorage.remove(StorageKey.PUPIL_TITLE)
    history.replace("/login")
}

function clearPupil(){
    AppStorage.remove(StorageKey.PUPIL_ID)
    AppStorage.remove(StorageKey.PUPIL_TITLE)
}

function MenuPerson(props){
    return (
        <MenuPersonWrapper to={props.to} $backToDashboard={props.backToDashboard} $isActive={props.isActive} onClick={props.menuClick}>
            <MenuItemLogo src={props.src} $size={props.size}/>
            <MenuItemTextLight> {props.text}</MenuItemTextLight>
        </MenuPersonWrapper>
    );
}

function MenuItemLight(props) {
    return (
        <MenuItemWrapper to={props.to} $backToDashboard={props.backToDashboard} $isActive={props.isActive} $paddingBottom={props.paddingBottom} onClick={props.menuClick}>
            <MenuItemLogo src={props.src} $size={props.size}/>
            <MenuItemTextLight> {props.text}</MenuItemTextLight>
        </MenuItemWrapper>
    );
}

function MenuItem(props) {
    return (
        <MenuItemWrapper to={props.to} $isActive={props.isActive}>
            <MenuItemLogo src={props.src}/>
            <MenuItemText> {props.text}</MenuItemText>
        </MenuItemWrapper>
    );
}

function LogOutButton(props) {
    return (
        <LogOutButtonWrapper onClick={props.onClick}>
            <MenuItemLogo src={props.src}/>
            <MenuItemText> {props.text}</MenuItemText>
        </LogOutButtonWrapper>
    );
}

function ExtendableMenuItem(props) {

    const [isExtended, setExtended] = useState(false);
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (location.pathname.includes("/meer")) {
            setExtended(true);
        }
    }, [location]);

    return (
        <ExtendableMenuItemWrapper>

            <RowWrapper onClick={() => expand()}>
                <ExtendableMenuItemContainer>
                    <MenuItemLogo src={props.src}/>
                    <MenuItemText> {props.text}</MenuItemText>
                </ExtendableMenuItemContainer>
                <MenuItemCaret src={isExtended ? caretUp : caretDown} alt={"pijltje"}/>
            </RowWrapper>

            {props.items.length !== 0 &&
            <ExtendableList $extended={isExtended}>
                {props.items.sort((a, b) => {
                    return a.sortOrder - b.sortOrder
                }).map((item, index) => {
                    return <ExtendableListItem
                        key={item.id}
                        $isActive={location.pathname.includes(item.id)}
                        onClick={() => navigate(item)}>
                        <ExtendableListItemText>{item.title}</ExtendableListItemText>
                    </ExtendableListItem>
                })}
            </ExtendableList>
            }
        </ExtendableMenuItemWrapper>
    );

    function expand() {
        setExtended(!isExtended);
    }

    function navigate(item) {
        history.push("/meer/" + item.id, {morePage: item});
    }
}

const SideMenuWrapper = styled.div`
    background: ${purpleDark};
    width: 250px;
    height: 100%;
    display: ${props => props.$visible ? "flex" : "none"};
    flex-direction: column;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 53px;
    flex-shrink: 0;
    padding-bottom: 39px;
    overflow-y: auto;
    overflow-x: hidden;
    div:last-of-type {
       margin-top: auto;
    }
`;

const MenuItemListSeparator = styled.div`
    height: 0.5px;
    background-color: rgba(255, 255, 255, 0.8);
    width: 220px;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
`;
const MenuItemList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    z-index: 10;
`;

const MenuLogo = styled.img`
    src: ${props => props.src};
    height: 179px;
    width: 188px;
    color: white;
    margin-bottom: 53px;
`;

const MenuBackground = styled.img`
    src: ${props => props.src};
    filter: opacity(7%);
    position: absolute;
    left: 0;
    bottom: 0;
    height: 179px;
    z-index: 1;
    width: 188px;
    color: white;
`;

const MenuItemWrapper = styled(Link)`
    text-decoration: none;
    background: ${props => props.$isActive ? red : undefined};
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.$backToDashboard ? "10px" : "25px"}; 
    height: 42px;
    width: 220px;
    padding-left: 13px;
    padding-right: 13px; 
    padding-bottom: ${props => props.$paddingBottom};
    text-overflow: ellipsis;
`;

const MenuPersonWrapper = styled.div`
    text-decoration: none;
    background: ${props => props.$isActive ? red : undefined};
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.$backToDashboard ? "10px" : "25px"}; 
    height: 42px;
    width: 220px;
    padding-left: 13px;
    padding-right: 13px; 
    text-overflow: ellipsis;
`;

const LogOutButtonWrapper = styled.div`
    text-decoration: none;
    background: ${props => props.$isActive ? red : undefined};
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
    height: 42px;
    width: 220px;
    padding-left: 13px;
    padding-right: 13px;
    cursor: pointer;
`;

const ExtendableMenuItemContainer = styled.div`
    text-decoration: none;
    background: ${props => props.$isActive ? red : undefined};
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
`;

const MenuItemLogo = styled.img`
    color: white;
    display: block;
    height:  ${props => props.$size ? props.$size : "24px"};
    width:  ${props => props.$size ? props.$size : "24px"};
    src: ${props => props.src};
`;

const MenuItemText = styled.h1`
    color: white;
    font-family: ${nunito};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
`;

const MenuItemTextLight = styled.h1`
    color: white;
    font-family: ${nunito};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const ExtendableMenuItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const ExtendableList = styled.div`
    display: flex;
    flex-direction: column;
    height: ${props => props.$extended ? "auto" : "0"};
    overflow: hidden;
    box-sizing: content-box;
    margin-left: 45px;
`;

const ExtendableListItem = styled.a`
    background: ${props => props.$isActive ? red : undefined};
    padding: 6px 16px;
    border-radius: 5px;
`;

const ExtendableListItemText = styled.span`
    font-family: ${nunito};
    font-weight: 400;
    font-size: 14px;
    color: ${white};
    text-decoration: none;
    opacity: 0.8;
    &:hover {
        color: ${white};
    }
`;

const MenuItemCaret = styled.img`
    
`;

const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 42px;
    width: 220px;
    padding-left: 13px;
    padding-right: 13px;
    cursor: pointer;
`;


export default SideMenu;