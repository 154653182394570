import {nunito, sourceSansPro} from "../../Theme";
import {ScoreIndicatorIcon} from "./PlanOfActionOverview";
import styled from "styled-components";
import {device} from "../../constants/breakpoints";
import {PlanOfActionTabs} from "../../constants/enums";

const HeaderLabel = styled.div`
    font-family: ${nunito};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    opacity: 0.7;
`;

const TitleHeader = styled(HeaderLabel)`
    width: 45%;
    @media screen and ${device.mobileL} {
        width: 70%;
    }
`;

const IndicatorHeader = styled(HeaderLabel)`
    width: 45%;
    @media screen and ${device.mobileL} {
        width: 20%;
    }
`;

const ScoreHeader = styled(HeaderLabel)`
    width: 10%;
`;

const OverViewHeader = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;

function OverViewCard(props) {

    return (
        <OverViewCardRoot {...props}>
            <OverViewHeader>
                <TitleHeader>Executieve functies</TitleHeader>
                <IndicatorHeader>Niveau</IndicatorHeader>
                <ScoreHeader>Score</ScoreHeader>
            </OverViewHeader>
            {(props.period ? props.period.scoresPerExecutiveFunction : []).map((ef, i) => {
                return  <ProgressListItem
                            key={ef.title}
                            index={i + 1} title={ef.title}
                            totalScore={ef.totalScore}
                            averageScore={ef.averageScore}/>
            })}
        </OverViewCardRoot>
    );

    function ProgressListItem(props) {
        return (
            <ScoreRow>
                <ScoreTitle>{props.index}. {props.title}</ScoreTitle>
                <ScoreIndicatorRoot>
                    <ScoreIndicatorIcon totalScore={props.totalScore}/>
                    <ScoreText>{determineText()}</ScoreText>
                </ScoreIndicatorRoot>
                <Score>
                    {props.totalScore}
                </Score>

            </ScoreRow>
        );

        function determineText() {
            if (!props.totalScore) {
                return "-"
            } else if (props.totalScore <= 10) {
                return "Startend";
            } else if (props.totalScore <= 15) {
                return "Ontwikkelend";
            } else {
                return "Beheerst";
            }
        }
    }
}

export default OverViewCard;

const ScoreTitle = styled.div`
    width: 45%;
    font-family ${sourceSansPro};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    
    @media screen and ${device.mobileL} {
        width: 70%;
    }
`;

const ScoreIndicatorRoot = styled.div`
    display: flex;
    flex-direction: row;
    gap: 7px;
    width: 45%;
    
    @media screen and ${device.mobileL} {
        width: 20%;
    }
`;

const Score = styled.div`
    width: 10%;
    font-family ${sourceSansPro};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
`;

const ScoreRow = styled.div`
    display: flex;
    flex-direction: row;
    height: 50px;
    border-bottom: 1px solid rgba(86, 89, 146, 0.1);
    align-items: center;
`;

const ScoreText = styled.div`
    font-family ${sourceSansPro};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    
    @media screen and ${device.mobileL} {
        display: none;
    }
`;

const OverViewCardRoot = styled.div`
    width: 100%; 
    display: ${props => props.activeTab === PlanOfActionTabs.EF ? "block" : "none"};
`