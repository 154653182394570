import React, {useEffect, useState} from 'react';
import BaseScreen from "../base/BaseScreen";
import styled from "styled-components";
import {black, nunito, purple, purpleLightest, sourceSansPro, white} from "../../Theme";
import Api from "../../util/Api";
import {useHistory, useLocation} from "react-router-dom";
import {device} from "../../constants/breakpoints";
import {ZoomImage} from "../executivefunction/ExecutiveFunctionScreen";
import zoomLogo from "../../resources/images/zoom.svg";
import showImagePopup from "../../popups/showImagePopup";
import {Loader} from "../../components/Loader";
import backIcon from "../../resources/images/back.svg";
import backIconWhite from "../../resources/images/backWhiteShadow.svg";
import {showNativeNavigation} from "../../AppInterface";

function MoreScreen() {
    const history = useHistory();
    const location = useLocation();
    const [page, setPage] = useState(history.location.state ? (history.location.state.morePage ?? {}) : {});

    useEffect(() => {
        showNativeNavigation();
    }, []);

    useEffect(() => {
        if(!history.location.state){
            Api.get('/more/' + location.pathname.replace("/meer/", ""), (response) => {
                setPage(response.data);
            })
        } else {
            setPage(history.location.state.morePage);
        }
        // eslint-disable-next-line
    }, [history.location.state]);

    const content = (
        <MoreScreenRoot>

            {Object.keys(page).length === 0 &&
                <Loader/>
            }

            {page && Object.keys(page).length !== 0 &&
            <ContentWrapper>
                <HeaderImageWrapper>
                    {page.imageUrl && [
                        <HeaderImage src={page.imageUrl} alt={"Afbeelding"}/>,
                        <ZoomImage src={zoomLogo} onClick={() => showImagePopup(page.imageUrl)}/>
                    ]}
                </HeaderImageWrapper>
                <MoreCardWrapper>
                    <MoreCard>
                        <Title>{page.title}</Title>
                        <Subtitle>{page.subtitle}</Subtitle>
                        <Text dangerouslySetInnerHTML={{__html: page.text}}/>
                    </MoreCard>
                </MoreCardWrapper>

                <BackButton src={page.imageUrl ? backIconWhite : backIcon} alt={"Pijltje naar links"} onClick={() => {
                    history.goBack()
                }} />
            </ContentWrapper>
            }
        </MoreScreenRoot>
    );

    return (
        <BaseScreen
            activeMenuItem={"Meer"}
            content={content}
        />
    )
}

export const MoreScreenRoot = styled.div`
    width: 100%;
    height: 100%;
    background: ${purpleLightest};
    position: relative;
`;

export const HeaderImageWrapper = styled.div`
    max-width: 790px;
    height: 404px;
    margin: auto;
    padding-top: 40px;
    box-sizing: content-box;
    position: relative;
    
    @media screen and ${device.mobileL} {
        height: 200px;
        padding-top: unset;
    }
`;

export const HeaderImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 3px;
    
    @media screen and ${device.mobileL} {
        border-radius: 0 0 3px 3px;
    }
`;

export const ContentWrapper = styled.div`
   
`;

export const MoreCardWrapper = styled.div`
   max-width: 679px;
   margin: auto;
   padding: 0 20px;
   box-sizing: content-box;
`;

export const MoreCard = styled.div`
   width: 100%;
   background: ${white};
   padding: 35px 40px;
   z-index: 10;
   position: relative;
   top: -110px;
   border: 1px solid rgba(86, 89, 146, 0.1);
   box-shadow: 0px 2px 0px rgba(86, 89, 146, 0.1);
   border-radius: 3px;
   
   @media screen and ${device.mobileL} {
        top: -40px;
        padding: 15px 20px;
   }
`;

export const Title = styled.h1`
   font-family: ${nunito};
   font-weight: 700;
   font-size: 28px;
   color: ${purple};
   margin: 0 0 10px 0;
   
   @media screen and ${device.mobileL} {
        font-size: 24px;
   }
`;

export const Subtitle = styled.div`
   font-family: ${sourceSansPro};
   font-weight: 400;
   font-size: 14px;
   font-style: italic;
   color: ${black};
   opacity: 0.7;
   margin: 0 0 20px 0;
`;

export const Text = styled.div`
   font-family: ${sourceSansPro};
   font-weight: 400;
   font-size: 16px;
   color: ${black};
   line-height: 20px;
   overflow: hidden;
   & a {
    text-decoration: underline;
   }
   & a:visited {
    color: purple;
   }
`;

export const BackButton = styled.img`
    width: 24px;
    height: 24px;
    position: absolute;
    left: 15px;
    top: 15px;
    cursor: pointer;
    display: none;
    
    @media screen and ${device.mobileL} {
        display: block;
    }
`;

export default MoreScreen;