import Swal from "sweetalert2";
import React, {useState} from "react";
import withReactContent from "sweetalert2-react-content";
import styled from "styled-components";
import "./opnijCustomPopup.scss";
import {black, Button, red, sourceSansPro, white} from "../Theme";
import closeIcon from "../resources/images/ic-closeWhite.svg";
import onboarding1 from "../resources/images/onboarding1.png";
import AppStorage, {StorageKey} from "../util/AppStorage";
import Api from "../util/Api";

export const OnboardingPopup = withReactContent(Swal)

function showOnboardingPopup() {
    OnboardingPopup.fire({
        html: (<PopupContent onClick={() => OnboardingPopup.close()}/>),
        showCancelButton: false,
        showConfirmButton: false,
        customClass: {
            container: "swal2-container-custom",
            htmlContainer: "swal2-html-container-custom",
            popup: "swal2-popup-custom"
        },
        heightAuto: false
    }).then(() => {
            AppStorage.set(StorageKey.FINISHED_ONBOARDING, true)
            Api.patch('/persons/' + AppStorage.get(StorageKey.USER_ID), {hasFinishedOnboarding: true})
        }
    );
}

function PopupContent(props) {
    const maxIndex = 0;
    const [currentIndex, setCurrentIndex] = useState(0)

    function getTitle() {
        switch (currentIndex) {
            case 0:
                return "Welkom bij de digitale EF-planagenda!";
            default: return "";
        }
    }

    function getText() {
        switch (currentIndex) {
            case 0:
                return "Leuk dat je de digitale versie van de EF-planagenda gaat gebruiken! Je kan de website als app op het startscherm van je device toevoegen. Klik op de drie puntjes rechtsboven als je op Chrome zit. Als je op Safari zit staan deze puntjes onderin.";
            default: return "";
        }
    }

    const indicators = [];
    for (let i = 0; i <= maxIndex; i++) {
        indicators.push(<Indicator isActive={i === currentIndex}/>)
    }

    return (
        <PopupContentRoot>
            <ImageRoot>
                <BackgroundImage
                    src={onboarding1}/>
                <CloseButton onClick={props.onClick} src={closeIcon}/>
            </ImageRoot>
            <TextRoot>
                <Title>
                    {getTitle()}
                </Title>
                <Text>
                    {getText()}
                </Text>
            </TextRoot>
            <BottomBar>
                <Indicators>
                    {indicators}
                </Indicators>
                <StyledButton onClick={clickedButton}>
                    {currentIndex < maxIndex ? "Volgende" : "Beginnen"}
                </StyledButton>
            </BottomBar>
        </PopupContentRoot>
    );

    function clickedButton() {
        if (currentIndex < maxIndex) {
            setCurrentIndex(currentIndex + 1)
        } else {
            props.onClick();
        }
    }
}

const StyledButton = styled(Button)`
    width: 124px;
`;

const Indicator = styled.div`
    background: ${red};
    opacity: ${props => props.isActive ? 1 : 0.3};
    border-radius: 7px;
    height: 7px;
    width: ${props => props.isActive ? 21 : 7}px;
`;
const Indicators = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
`;

const BottomBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
`;

const TextRoot = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
`

const ImageRoot = styled.div`
    display: relative;
`;

const Text = styled.div`
    color: ${black};
    font-family: ${sourceSansPro};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    margin-top: 8px;
`;

const Title = styled.div`
    font-family: ${sourceSansPro};
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    text-align: left;
    color: ${red};
`;

const PopupContentRoot = styled.div`
    max-width: 416px;
    margin: auto;
    background: ${white};
    border-radius: 3px;
`;

const BackgroundImage = styled.img`
    height: 225px;
    width: 100%;
    object-fit: cover;
    border-radius: 3px 3px 0px 0px;
`;

const CloseButton = styled.img`
   max-width: 24px;
   max-height: 24px;
   position: absolute;
   right: 45px;
   cursor: pointer;
`;


export default showOnboardingPopup;