import './App.scss';
import LoginScreen from "./screens/login/LoginScreen";
import ScheduleScreen from "./screens/schedule/ScheduleScreen";
import ExamOverviewScreen from "./screens/examoverview/ExamOverviewScreen";
import {BrowserRouter, Switch, useLocation} from "react-router-dom";
import NotFound from "./screens/NotFound";
import ExamScreen from "./screens/exam/ExamScreen";
import moment from "moment";
import HomeworkItemScreen from "./screens/homeworkitem/HomeworkItemScreen";
import ExecutiveFunctionOverviewScreen from "./screens/executivefunction/ExecutiveFunctionOverviewScreen";
import ExecutiveFunctionScreen from "./screens/executivefunction/ExecutiveFunctionScreen";
import MoreScreen from "./screens/more/MoreScreen";
import MoreScreenOverview from "./screens/more/MoreScreenOverview";
import PlanOfActionQuestionnaire from "./screens/planofaction/PlanOfActionQuestionnaire";
import PlanOfActionOverview from "./screens/planofaction/PlanOfActionOverview";
import PlanOfActionScreen from "./screens/planofaction/PlanOfActionScreen";
import SetPasswordScreen from "./screens/login/SetPasswordScreen";
import ResetPasswordScreen from "./screens/login/ResetPasswordScreen";
import {Desktop, Mobile} from "./Theme";
import SideMenu from "./menu/SideMenu";
import BottomMenu from "./menu/BottomMenu";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {device} from "./constants/breakpoints";
import AppStorage, {StorageKey} from "./util/AppStorage";
import TopMenu from "./menu/TopMenu";
import DashboardPupilsScreen from "./screens/dashboard/DashboardPupilsScreen";
import PrivateRoute from "./util/PrivateRoute";
import {useIsUserAuthenticated} from "./components/hooks/useIsUserAuthenticated";
import SentryRoute from './util/SentryRoute';

function App() {

    const userIsAuthenticated = useIsUserAuthenticated();

    /**
     * Mobile viewport fix
     * @type {number}
     */
    function setViewPortMobile() {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    window.addEventListener('resize', setViewPortMobile);
    setViewPortMobile();

    // set locale
    moment.locale('nl')

    return (
        <AppWrapper>
            <BrowserRouter>

                {userIsAuthenticated &&
                    <>
                        <Desktop>
                            <SideMenu/>
                        </Desktop>

                        <Mobile>
                            <TopMenu/>
                        </Mobile>
                    </>
                }

                <Switch>

                    {publicRoutes.map(route => {
                        return (
                            <SentryRoute
                                key={route.path}
                                path={route.path}
                                exact={route.exact}
                                component={route.component}
                            />
                        )
                    })}

                    {privateRoutes.map(route => {
                        return (
                            <PrivateRoute
                                key={route.path}
                                userIsAuthenticated={userIsAuthenticated}
                                path={route.path}
                                exact={route.exact}
                                component={route.component}
                            />
                        )
                    })}
                </Switch>

                <Mobile>
                    <BottomMenu/>
                </Mobile>
            </BrowserRouter>
        </AppWrapper>
    );
}

export function isInReadOnlyMode() {
    return AppStorage.get(StorageKey.PUPIL_ID) !== AppStorage.get(StorageKey.USER_ID)
}

export function isInDashboard() {
    return (window.location.href.indexOf("dashboard") > -1)
}

export function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

export function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const publicRoutes = [
    {
        path: '/login',
        exact: true,
        component: LoginScreen
    },
    {
        path: '/verander-wachtwoord',
        exact: true,
        component: SetPasswordScreen
    },
    {
        path: '/herstel-wachtwoord',
        exact: true,
        component: ResetPasswordScreen
    }
]

export const privateRoutes = [
    {
        path: '/',
        exact: true,
        component: ScheduleScreen
    },
    {
        path: '/executieve-functies/:id',
        exact: true,
        component: ExecutiveFunctionScreen
    },
    {
        path: '/executieve-functies',
        exact: true,
        component: ExecutiveFunctionOverviewScreen
    },
    {
        path: '/toets/:id?',
        exact: true,
        component: ExamScreen
    },
    {
        path: '/huiswerk/:id?',
        exact: true,
        component: HomeworkItemScreen
    },
    {
        path: '/toetsweek',
        exact: true,
        component: ExamOverviewScreen
    },
    {
        path: '/meer/:id',
        exact: true,
        component: MoreScreen
    },
    {
        path: '/meer',
        exact: true,
        component: MoreScreenOverview
    },
    {
        path: '/plan-van-aanpak/:id/vragenlijst',
        exact: true,
        component: PlanOfActionQuestionnaire
    },
    {
        path: '/plan-van-aanpak/:id',
        exact: true,
        component: PlanOfActionScreen
    },
    {
        path: '/plan-van-aanpak',
        exact: true,
        component: PlanOfActionOverview
    },
    {
        path: '/dashboard',
        exact: true,
        component: DashboardPupilsScreen
    },
    {
        path: '/',
        exact: false,
        component: NotFound
    },
];

export const RoutesWithoutNav = [
    "/login",
    "/verander-wachtwoord",
    "/herstel-wachtwoord",
    "/selecteer-leerling"
];

const AppWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    
    @media screen and ${device.tablet} {
      flex-direction: column;
    }
`;


export default App;