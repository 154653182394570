import styled, {keyframes} from "styled-components";
import {device} from "./constants/breakpoints";

/**
 * Colors
 * @type {string}
 */
export const purple = "#5E4187";
export const purpleDark = "#2F1C41";
export const purpleLight = "#E0DBF8";
export const purpleLighter = "#ECEBF7";
export const purpleLightest = "#F9F8FF";
export const violet = "#B998B9";
export const red = "#DC492F";
export const green = "#22a535";
export const yellow = "#FFDD75";
export const greyLight = "#E2E2EB";
export const blueLight = "#CDE8FC";
export const black = "#000000";
export const white = "#FFFFFF";

/**
 */

export const nunito = "\"Nunito\", sans-serif";
export const sourceSansPro = "\"Source Sans Pro\", sans-serif";
export const capriola = "'Capriola', sans-serif";
export const fjallaOne = "'Fjalla One', sans-serif";
export const lemon = "'Lemon', cursive";
export const lobster = "'Lobster', cursive";
export const markoOne = "'Marko One', serif";


export const Button = styled.button`
    background: #5E4187;
    font-family: ${sourceSansPro};
    font-size: 0.9375em;
    font-weight: 600;
    color: #FFFFFF;
    max-width: ${props => props.maxWidth ? props.maxWidth : '170px'};
    width: 100%;
    min-height: 35px;
    border: none;
    padding: 8px;
    border-bottom: #4D2F78 3px;
    border-radius: 3px;
    &:hover {
        cursor: pointer;
    }
`;

const Spinner = keyframes`
    from { transform: rotate(0); }
    to { transform: rotate(360deg); }
`;

export const ButtonSpinner = styled.div`
    position: relative;
    top: 1px;
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 2px solid rgba(255, 255, 255, .2);
    border-radius: 50%;
    border-top-color: ${purpleLight};
    animation: ${Spinner} 1s ease-in-out infinite;
`;

export const Checkbox = styled.input`

`

export const Input = styled.input`
    width: 100%;
    border-radius: 2px;
    border: none;
    box-shadow: 0 0 0 2px $grey-light;
    height: 40px;
    margin-bottom: 35px;
    padding: 0 9px;
`;

export const BasicGrid = styled.div`
    display: grid;
    grid-template-columns: ${props => props.columnDivision || "1fr 1fr 1fr"};
    row-gap: ${props => props.rowGap || "25px"};
    column-gap: ${props => props.columnGap || "40px"};
    max-width: ${props => props.maxWidth || "1050px"};
    
    @media screen and ${device.laptop} {
        grid-template-columns: ${props => props.laptopColumnDivision || "1fr 1fr"};;
        column-gap: ${props => props.laptopColumnGap || "15px"};
        row-gap: ${props => props.laptopRowGap || "15px"};
    }
    
    @media screen and ${device.mobileL} {
        grid-template-columns: ${props => props.mobileColumnDivision || "1fr"};
        column-gap: ${props => props.mobileColumnGap || "15px"};
        row-gap: ${props => props.mobileRowGap || "15px"};
    }
`;

export const Desktop = styled.div`
  display: block;
  @media screen and ${device.tablet} {
    display: none;
  }
`;

export const Mobile = styled.div`
  display: none;
  @media screen and ${device.tablet} {
    display: block;
  }  
`;

export const BackgroundedScreen = styled.div`
    width: 100%;
    height: 100%;
    background: linear-gradient(${purpleLighter} 250px, ${purpleLightest} 250px);
    position: relative;
    overflow-x: hidden;
`;

export const SmallHeader = styled.div`
    background: ${props => props.backgroundColor ? props.backgroundColor : purpleLighter};
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

export const Header = styled.div`
    margin-top: 55px;
    margin-bottom: 27px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    img:first-of-type {
       margin-right: auto;
    }
    img:last-of-type {
       margin-left: auto;
    }
    @media screen and ${device.mobileL} {
        margin-top: 10px;
        margin-bottom: 20px;
    }
`

export const HeaderTitle = styled.div`
    font-family: ${nunito};
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    color: ${purple};
    @media screen and ${device.mobileL} {
        font-size: 18px;
    }
`
export const SmallHeaderTitle = styled.div`
    font-family: ${nunito};
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    color: ${props => props.color ? props.color : purple};
`

export const HeaderLogo = styled.img`
    cursor: pointer;
    color: white;
    height: 24px;
    visibility: ${props => props.visibility || 'default'};
    width: 24px;
    src: ${props => props.src};
`

export const Card = styled.div`
    background: ${white};
    width: 100%;
    border: 1px solid rgba(86, 89, 146, 0.1);
    box-shadow: 0px 2px 0px rgba(86, 89, 146, 0.1);
    border-radius: 3px;
    padding: ${props => props.padding || "38px 67px 38px 67px"};
    
    @media screen and ${device.mobileL} {
        padding: ${props => props.mobilePadding ? props.mobilePadding : "15px 20px 15px 20px"};
        display: ${props => props.hideOnMobile ? 'none' : 'block'};
    }
`

export const ClickableCard = styled(Card)`
    cursor: pointer;
`;

export const CardTitle = styled.h2`
    color: ${red};
    font-family: ${nunito};
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    margin: 0 0 24px 0;
`