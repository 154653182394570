import React, {useEffect, useState} from 'react';
import BaseScreen from "../base/BaseScreen";
import styled from "styled-components";
import logoutIcon from "../../resources/images/ic-logoutPurple.svg";
import chevronRight from "../../resources/images/ic-chevronRightRed.svg"
import {nunito, purple, purpleLightest, SmallHeader} from "../../Theme";
import {useHistory} from "react-router-dom";
import Api from "../../util/Api";
import decoration from "../../resources/images/decorationDark.svg";
import {Loader} from "../../components/Loader";
import {logoutAndGoToLogin} from "../../menu/SideMenu";
import {showNativeNavigation} from "../../AppInterface";

function MoreScreenOverview() {
    const history = useHistory();
    const [morePages, setMorePages] = useState([]);

    useEffect(() => {
        showNativeNavigation();
        Api.get('/more', (response) => {
            setMorePages(response.data);
        })
    }, [])

    const content = (
        <MoreScreenOverviewRoot>

            {morePages.length === 0 &&
                <Loader/>
            }

            <SmallHeader>
                <HeaderTitle>Meer</HeaderTitle>
                <LogoutButton src={logoutIcon} alt={"Uitlog icoon"} onClick={() => logoutAndGoToLogin(history)}/>
            </SmallHeader>
            <Decoration src={decoration} alt={"decoratie"}/>

            <MoreListWrapper>
                <MoreList>
                    {morePages.sort((a, b) => {
                        return a.sortOrder - b.sortOrder
                    }).map((morePage, index) => {
                        return (
                            <MoreListItem key={morePage.id} onClick={() => history.push("/meer/" + morePage.id, {morePage: morePage})}>
                                <MorePageTitle>{morePage.title}</MorePageTitle>
                                <MoreListItemIcon src={chevronRight} alt={"pijl naar rechts"}/>
                            </MoreListItem>
                        )
                    })}
                </MoreList>
            </MoreListWrapper>

        </MoreScreenOverviewRoot>
    );

    return (
        <BaseScreen
            activeMenuItem={"Meer"}
            content={content}
        />
    )
}

export const MoreScreenOverviewRoot = styled.div`
    width: 100%;
    height: 100%;
    background: ${purpleLightest};
    position: relative;
    overflow: hidden;
`;

export const HeaderTitle = styled.div`
    font-family: ${nunito};
    font-weight: 700;
    font-size: 21px;
    color: ${purple};
`;

export const LogoutButton = styled.img`
    position: absolute;
    right: 33px;
`;

export const MoreListWrapper = styled.div`
    margin: auto;
    max-width: 500px;
    padding-top: 20px;
    position: relative;
    z-index: 2;
`;

export const MoreList = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    
    @media screen and (max-width: 520px) {
        margin-left: 18px;
    }
`;

export const MoreListItem = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(86, 89, 146, 0.1);
    cursor: pointer;
`;

export const MorePageTitle = styled.div`
    font-family: ${nunito};
    font-weight: 700;
    font-size: 16px;
    color: ${purple};
`;

export const MoreListItemIcon = styled.img`
    @media screen and (max-width: 520px) {
        margin-right: 30px;
    }
`;

export const Decoration = styled.img`
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: -30px;
    @media screen and (max-width: 520px) {
        width: 175px;
    }
`;

export default MoreScreenOverview;