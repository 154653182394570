import React, {useEffect, useState} from 'react';
import styled, {keyframes} from "styled-components";

/*
        Export this loader in a global const like so:

        export const exampleLoader = () => {
            <GlobalLoader
                *props*
            />
        }

        Now you can use this loader anywhere inside your app without having to constantly redefine it's props.

        Props:

        - image                 *Sets the image of the loader*
        - imageWidth            *Sets the width of the image*                   *default: 60px*

        - text                  *Sets text of the loader*
        - textFont              *Sets the font of the text*                     *default: Helvetica*
        - textSize              *Sets the size of the text*                     *default: 21px*
        - textWeight            *Sets the weight of the text*                   *default: normal*
        - textColor             *Sets the color of the text*                    *default: black*
        - textImageGap          *Sets the gap size between image and text*      *default: 10px*

        - imageWidthMobile      *Sets the width of the image*                   *default: 40px*
        - textSizeMobile        *Sets the size of the text*                     *default: 16px*
        - textImageGapMobile    *Sets the gap size between image and text*      *default: 5px*


            text={"Laden..."}
            textFont={nunito}
            textSize={"20px"}
            textSizeMobile={"15px"}
            textWeight={"600"}
            textColor={purple}
            textImageGap={"10px"}
            textImageGapMobile={"7px"}

        ****************** IMPORTANT ******************

        Make sure the loader's parent position is set to 'relative' in order to center this loader inside it's container

        ***********************************************

    */

function GlobalLoader(props) {

    const [isVisible, setVisible] = useState(!props.delay);

    useEffect(() => {
        let delayTimeout;

        if (props.delay) {
            delayTimeout = setTimeout(() => {
                setVisible(true);
            }, 200);
        }

        return () => {
            clearTimeout(delayTimeout);
        }
        // eslint-disable-next-line
    }, []);

    const showText = !!props.text;

    return (
        <LoaderRoot>
            {isVisible &&
            <LoaderWrapper>
                <AnimatedImage>
                    <LoaderImage
                        {...props}
                        src={props.image}
                    />
                </AnimatedImage>
                {showText &&
                <LoaderText {...props}>
                    {props.text}
                </LoaderText>
                }
            </LoaderWrapper>
            }
        </LoaderRoot>
    );
}

const LoaderRoot = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
`;

const LoaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${props => props.textImageGap ? props.textImageGap : "10px"};
    
    @media screen and (max-width: 425px) {
        gap: ${props => props.textImageGapMobile ? props.textImageGapMobile : "5px"};
    }
`;

const LoaderImage = styled.img`
    width: ${props => props.imageWidth ? props.imageWidth : "60px"};
    
    @media screen and (max-width: 425px) {
        width: ${props => props.imageWidthMobile ? props.imageWidthMobile : "40px"};
    }
`;

const LoaderText = styled.div`
    font-family: ${props => props.textFont ? props.textFont : "'Helvetica', sans-serif"};
    font-weight: ${props => props.textWeight ? props.textWeight : "normal"};
    font-size: ${props => props.textSize ? props.textSize : "21px"};
    color: ${props => props.textColor ? props.textColor : "black"};
    
    @media screen and (max-width: 425px) {
        width: ${props => props.textSizeMobile ? props.textSizeMobile : "16px"};
    }
`;

const rotate = keyframes`
    0%   { -webkit-transform: rotate(   0deg); }
    100% { -webkit-transform: rotate(720deg); }
`;

const AnimatedImage = styled.div`
    animation: ${rotate} 1.2s ease infinite;
`;


export default GlobalLoader;