import React, {useState} from "react";
import {Answer} from "../../constants/enums";
import Api from "../../util/Api";
import AppStorage, {StorageKey} from "../../util/AppStorage";
import styled from "styled-components";
import {purple, sourceSansPro, white} from "../../Theme";
import {isInReadOnlyMode} from "../../App";

function QuestionAnswerRow(props) {
    const [selectedAnswer, setSelectedAnswer] = useState(props.answer ? parseInt(props.answer.value) : undefined);
    const userUuid = AppStorage.get(StorageKey.USER_ID);

    return (
        <QuestionAnswerRowRoot>
            <AnswerButton
                isActive={!isInReadOnlyMode()}
                answerValue={Answer.NEVER}
                isSelected={selectedAnswer === Answer.NEVER}
                onClick={() => handleAnswerClick(props.question.id, Answer.NEVER)}
            />

            <AnswerButton
                isActive={!isInReadOnlyMode()}
                answerValue={Answer.SOMETIMES}
                isSelected={selectedAnswer === Answer.SOMETIMES}
                onClick={() => handleAnswerClick(props.question.id, Answer.SOMETIMES)}
            />

            <AnswerButton
                isActive={!isInReadOnlyMode()}
                answerValue={Answer.OFTEN}
                isSelected={selectedAnswer === Answer.OFTEN}
                onClick={() => handleAnswerClick(props.question.id, Answer.OFTEN)}
            />

            <AnswerButton
                isActive={!isInReadOnlyMode()}
                answerValue={Answer.ALWAYS}
                isSelected={selectedAnswer === Answer.ALWAYS}
                onClick={() => handleAnswerClick(props.question.id, Answer.ALWAYS)}
            />
        </QuestionAnswerRowRoot>
    );

    function AnswerButton(props) {
        return (
            <AnswerButtonRoot isActive={props.isActive} selected={props.isSelected} onClick={props.onClick}>
                <AnswerButtonText
                    selected={props.isSelected}>{mapAnswerValueToString(props.answerValue)}</AnswerButtonText>
            </AnswerButtonRoot>
        );
    }

    function handleAnswerClick(questionId, answerValue) {
        const answer = props.answer;
        const answerId = props.answer ? props.answer.id : ""
        const answerData = {
            id: answer ? answer.id : null,
            questionId: questionId,
            periodId: props.periodId,
            value: answerValue
        }
        putAnswer(answerData, answerValue, answerId);
    }

    function mapAnswerValueToString(answerValue) {
        switch (answerValue) {
            case Answer.NEVER:
                return "Nooit";
            case Answer.SOMETIMES:
                return "Soms";
            case Answer.OFTEN:
                return "Vaak";
            case Answer.ALWAYS:
                return "Altijd";
            default:
                return "";
        }
    }

    function putAnswer(answerData, answerValue, answerId) {
        const config = {
            params: {
                'filter[periodId]': props.periodId
            }
        }

        Api.put("/pupils/" + userUuid + "/answers/" + answerId, answerData, (response) => {
            props.onPut(props.answer, response.data)
            setSelectedAnswer(answerValue);
        }, null, null, null, config);
    }
}

export const AnswerButtonRoot = styled.div`
    width: 68px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #5E4187;
    border-radius: 3px;
    background: ${props => props.selected ? purple : undefined};
    pointer-events: ${props => props.isActive ? 'default' : 'none'};
    cursor: pointer;
`;

export const AnswerButtonText = styled.div`
    font-family: ${sourceSansPro};
    font-weight: 400;
    font-size: 16px;
    color: ${props => props.selected ? white : purple}
`;

export const QuestionAnswerRowRoot = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
`;

export default QuestionAnswerRow;