import React, {useEffect, useState} from 'react';
import BaseScreen from "../base/BaseScreen";
import {black, nunito, purple, purpleLighter, purpleLightest, red, sourceSansPro, white} from "../../Theme";
import closeIcon from "../../resources/images/ic-closePurple.svg";
import forwardIcon from "../../resources/images/forwardWhite.svg";
import backIcon from "../../resources/images/ic-arrowLeft.svg"
import styled from "styled-components";
import {device} from "../../constants/breakpoints";
import Api from "../../util/Api";
import AppStorage, {StorageKey} from "../../util/AppStorage";
import {useHistory} from "react-router-dom";
import QuestionAnswerRow from "./QuestionAnswerRow";
import {Loader} from "../../components/Loader";
import {useGlobalState} from "../../util/GlobalState";
import {showNativeNavigation} from "../../AppInterface";

function PlanOfActionQuestionnaire(props) {
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [steps, setSteps] = useState([]);
    const [answers, setAnswers] = useState(null);
    const [isNative] = useGlobalState('isNative', false)
    const history = useHistory();
    const periodId = (history.location.state && history.location.state.period) ? history.location.state.period.id : props.match.params.id;

    useEffect(() => {
        showNativeNavigation();
        getQuestionnaire();
        getAnswers()
        // eslint-disable-next-line
    }, [])

    const lastStep = currentStepIndex === (steps.length - 1);
    const firstStep = currentStepIndex === 0;
    const step = steps[currentStepIndex];

    const content = (
        <PlanOfActionQuestionnaireRoot>

            {steps.length === 0 && answers === null &&
                <Loader/>
            }

            {steps.length !== 0 && answers !== null &&
                <React.Fragment>
                    <QuestionnaireWrapper>
                        <QuestionnaireHeader>
                            <QuestionnaireTitle>Vragenlijst {props.period}</QuestionnaireTitle>
                            <CancelButton src={closeIcon} alt={"Sluiten"}
                                          onClick={() => history.push("/plan-van-aanpak")}/>
                        </QuestionnaireHeader>

                        <QuestionnaireCard>
                            <CardTitle>{step.title}</CardTitle>

                            {step.type === "questions"
                                ?
                                <QuestionsList>
                                    {steps[currentStepIndex].questions.sort((a, b) => {
                                        return a.sortOrder - b.sortOrder
                                    }).map((question, index) => {
                                        const answer = answers.find(a => a.questionId === question.id);
                                        return <QuestionWrapper key={question.id}>
                                            <QuestionTitle>{question.title}</QuestionTitle>
                                            <QuestionAnswerRow
                                                question={question}
                                                answer={answer}
                                                periodId={periodId}
                                                onPut={(oldAnswer, newAnswer) => handleNewAnswerGiven(oldAnswer, newAnswer)}
                                            />
                                        </QuestionWrapper>
                                    })}
                                </QuestionsList>
                                :
                                <CardText>
                                    {step.text}
                                </CardText>
                            }
                        </QuestionnaireCard>

                        <DesktopFooter/>
                    </QuestionnaireWrapper>
                    <TabletFooter/>
                </React.Fragment>
            }
        </PlanOfActionQuestionnaireRoot>
    );

    return (
        <BaseScreen
            activeMenuItem={"Plan van aanpak"}
            content={content}
        />
    )

    function DesktopFooter() {
        return <DesktopQuestionnaireFooter isNative={isNative}>
            <FooterContent/>
        </DesktopQuestionnaireFooter>
    }

    function TabletFooter() {
        return <TabletQuestionnaireFooter isNative={isNative}>
            <FooterContent/>
        </TabletQuestionnaireFooter>
    }

    function FooterContent() {
        return <React.Fragment>
            <StepIndicator>Stap {currentStepIndex + 1} van {steps.length}</StepIndicator>

            <NextStepButton onClick={() => handleNextStepButtonClick()}>
                <NextStepButtonText>{lastStep ? "Afronden" : "Volgende"}</NextStepButtonText>
                {!lastStep && <NextStepButtonIcon src={forwardIcon} alt={"Pijltje naar rechts"}/>}
            </NextStepButton>

            {!firstStep &&
                <PreviousStepButton onClick={() => handlePreviousStepButtonClick()}>
                    <PreviousStepButtonIcon src={backIcon} alt={"Pijltje naar links"}/>
                    <PreviousStepButtonText>Vorige</PreviousStepButtonText>
                </PreviousStepButton>
            }
        </React.Fragment>
    }

    function handleNextStepButtonClick() {
        if (!lastStep) {
            setCurrentStepIndex(currentStepIndex + 1);
        } else {
            history.push("/plan-van-aanpak")
        }
    }

    function handlePreviousStepButtonClick() {
        if (!firstStep) {
            setCurrentStepIndex(currentStepIndex - 1);
        }
    }

    function getQuestionnaire() {
        Api.get("/questionnairePages", (response) => {
            let sortedResponse = response.data.sort((a, b) => {
                return a.sortOrder - b.sortOrder
            });
            setSteps(sortedResponse);
        });
    }

    function handleNewAnswerGiven(oldAnswer, newAnswer) {
        if(oldAnswer) {
            const oldAnswerInAnswers = answers.find(a => a.id === oldAnswer.id)
            if (oldAnswerInAnswers === undefined) {
                addNewAnswer(newAnswer)
            } else {
                replaceOldAnswerWithNewAnswer(oldAnswer, newAnswer)
            }
        } else {
            addNewAnswer(newAnswer)
        }
    }

    function addNewAnswer(newAnswer) {
        // add to local state if there was no previous value
        setAnswers([...answers, newAnswer])
    }

    function replaceOldAnswerWithNewAnswer(oldAnswer, newAnswer) {
        // replace old answer with new answer in local answers state
        let answersCopy = [...answers]
        let oldAnswerIndex = answersCopy.findIndex(answer => {
            return answer.id === oldAnswer.id
        })
        if(oldAnswerIndex >= 0) {
            answersCopy[oldAnswerIndex] = newAnswer
            setAnswers(answersCopy)
        } else {
            addNewAnswer(newAnswer)
        }
    }

    function getAnswers() {
        const config = {
            params: {
                'filter[periodId]': periodId,
            }
        }

        Api.get("/pupils/" + AppStorage.get(StorageKey.PUPIL_ID) + "/answers", (response) => {
            setAnswers(response.data);
        }, null, null, null, config);
    }
}

export default PlanOfActionQuestionnaire;

export const PlanOfActionQuestionnaireRoot = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    background: linear-gradient(${purpleLighter} 250px, ${purpleLightest} 250px);
    display: flex;
    flex-direction: column;
`;

export const QuestionnaireWrapper = styled.div`
    width: 100%;
    max-width: 620px;
    margin: 0 auto;
    padding: 55px 17px 110px 17px;

    @media screen and ${device.tablet} {
        overflow-y: auto;
        flex: 1 1 0;
    }
    
    @media screen and ${device.mobileL} {
        padding: 10px 17px 110px 17px;
    }
`;

export const QuestionnaireHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 27px;
`;

export const QuestionnaireTitle = styled.div`
    font-family: ${nunito};
    font-weight: 700;
    font-size: 28px;
    color: ${purple};
    
    @media screen and ${device.mobileL} {
        font-size: 18px;
    }
`;


export const CancelButton = styled.img`
    cursor: pointer;
    position: absolute;
    right: 0;
`;

export const QuestionnaireCard = styled.div`
    width: 100%;
    padding: 35px 65px;
    background: ${white};
    border: solid rgba(86, 89, 146, 0.1);
    border-width: 1px 1px 0 1px;
    border-radius: 3px;
    
    @media screen and ${device.mobileL} {
        padding: 20px 15px;
    }
`;

export const CardTitle = styled.h2`
    font-family: ${nunito};
    font-weight: 700;
    font-size: 21px;
    color: ${red};
    margin: 0 0 20px 0;
`;

export const CardText = styled.div`
    font-family: ${sourceSansPro};
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${black};
`;

export const DesktopQuestionnaireFooter = styled.div`
    width: 100%;
    height: 60px;
    background: ${purpleLighter};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0px 2px 0px rgba(86, 89, 146, 0.1);
    border-radius: 0px 0px 3px 3px;
    
    @media screen and ${device.tablet} {
        display: none;
    }
`;

export const TabletQuestionnaireFooter = styled.div`
    display: none;
    
    @media screen and ${device.tablet} {
        width: 100%;
        height: 60px;
        background: ${purpleLighter};
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
        box-shadow: 0px 2px 0px rgba(86, 89, 146, 0.1);
        border-radius: 0px 0px 3px 3px;
        flex: 0 0 auto;
    }
`;

export const StepIndicator = styled.div`
    font-family: ${sourceSansPro};
    font-weight: 400;
    font-size: 12px;
    color: ${purple};
`;

export const NextStepButton = styled.div`
    width: 125px;
    height: 37px;
    background: ${purple};
    box-shadow: 0px 2px 0px #4D2F78;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 7px;
    position: absolute;
    right: 14px;
    cursor: pointer;
`;

export const NextStepButtonText = styled.div`
    font-family: ${sourceSansPro};
    font-weight: 600;
    font-size: 15px;
    color: ${white};
`;

export const NextStepButtonIcon = styled.img`
    width: 17px;
`;

export const PreviousStepButton = styled.div`
    display: flex;
    flex-direction: row;
    gap: 7px;
    align-items: center;
    position: absolute;
    left: 20px;
    padding: 10px 15px;
    cursor: pointer;
`;

export const PreviousStepButtonText = styled.div`
    font-family: ${sourceSansPro};
    font-weight: 600;
    font-size: 15px;
    color: ${purple};
`;

export const PreviousStepButtonIcon = styled.img`
    width: 17px;
`;

export const QuestionsList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 45px;
    padding-bottom: 10px;
`;

export const QuestionWrapper = styled.div`
    
`;

export const QuestionTitle = styled.div`
    font-family: ${nunito};
    font-weight: 700;
    font-size: 16px;
    color: ${black};
    margin-bottom: 11px;
`;