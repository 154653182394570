import React, {useEffect, useState} from "react";
import BaseScreen from "../base/BaseScreen";
import styled from "styled-components";
import {purpleLightest} from "../../Theme";
import Api from "../../util/Api";
import {useHistory} from "react-router-dom";
import {Loader} from "../../components/Loader";
import AppStorage, {StorageKey} from "../../util/AppStorage";
import {DateNavigatorMode} from "../../constants/enums";
import showBasicPopup from "../../popups/showBasicPopup";
import moment from "moment";
import PupilCard from "./PupilCard";
import {device} from "../../constants/breakpoints";
import DateNavigator from "../../components/date-navigator/DateNavigator";
import {useGlobalState} from "../../util/GlobalState";
import {useWindowSize} from "../../App";
import DashboardDropdownButton from "./DashboardDropdownButton";
import {hideNativeNavigation} from "../../AppInterface";

function DashboardPupilsScreen() {
    const history = useHistory();
    const [pupils, setPupils] = useState(null);
    const [currentMoment, setCurrentMoment] = useState(moment());
    const [selectedFilter] = useGlobalState( "selectedFilter",{id: null, title: "Alle"})
    const [isDashboard] = useGlobalState('isDashboard', true);
    const [overlayActive] = useGlobalState('overlayActive', false);
    const [currentMode, setCurrentMode] = useState(DateNavigatorMode.WEEK)
    const [grades, setGrades] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const size = useWindowSize()


    useEffect(() => {
        hideNativeNavigation();
        getPupils();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (pupils) {
            filterGrades()
        }
        // eslint-disable-next-line
    }, [pupils])

    useEffect(() => {
        getPupils()
        // eslint-disable-next-line
    }, [currentMoment, currentMode, selectedFilter]);

    useEffect(() => {
        if (window.location.href.indexOf("dashboard") > -1) {
            AppStorage.remove(StorageKey.PUPIL_ID)
            AppStorage.remove(StorageKey.PUPIL_TITLE)
        }
        // eslint-disable-next-line
    }, [window.location])

    const PupilCardComponent = ({items}) => {
        items.sort((a, b) => (a.surname > b.surname ? 1 : -1));

        return items.map((element, key) => {
            return <PupilCard
                key={key}
                firstname={element.firstname}
                onClick={() => {goToScheduleScreen(element)}}
                surname={element.surname}
                totalExams={element.totalExams}
                totalHomeworkItems={element.totalHomeworkItems}
                totalScheduledExams={element.totalScheduledExams}
                totalScheduledHomeworkItems={element.totalScheduledHomeworkItems}
                totalFinishedExams={element.totalFinishedExams}
                totalFinishedHomeworkItems={element.totalFinishedHomeworkItems}
                hasUnreadNotifications={element.hasUnreadNotifications}
            /> })
    }

    const content = (
            <DashboardRoot
                overlayActive={overlayActive}>
                <DateNavigator
                    isOnDashboard={true}
                    onAfterNavigation={(newMoment) => {setCurrentMoment(newMoment)}}
                    onModeSwitch={(newMode) => { setCurrentMode(newMode)}}
                    defaultNavigatorMode={currentMode}
                    momentObject={currentMoment}
                    disableNotificationMenu={true}
                    gradeItems={grades}
                    hasPupils={!!pupils}
                />


                {/*If pupils is empty*/}
                {!pupils && isLoading && <Loader/>}
                {pupils && pupils.length !== 0 && selectedFilter &&
                    <DashboardContainer>
                        { size.width <= 768 &&
                            <DashboardDropdownButton
                                primary
                                type={"Klas"}
                                items={grades}
                                isMobile={true}
                            />
                        }
                        <DashboardGrid>
                            <PupilCardComponent
                                items={filterSetting()}
                            />
                        </DashboardGrid>
                    </DashboardContainer>
                }
            </DashboardRoot>
    );

    return (
        <BaseScreen
            activeMenuItem={"Mentoren dashboard"}
            content={content}
            isDashboard={isDashboard}
        />
    )

    function filterSetting() {
        if (selectedFilter.id !== null) {
            return pupils.data.filter(pupil => pupil.grade.id.includes(selectedFilter.id))
        } else {
            return pupils.data
        }
    }

    function getPupils() {
        setIsLoading(true)
        const data = getPostData();
        Api.post("/persons/" + AppStorage.get(StorageKey.USER_ID) + "/dashboard", data, (response) => {
            setPupils(response.data ?? [])
            setIsLoading(false)
        }, null, null, () => {
            setIsLoading(false)
            showBasicPopup("Ophalen van studenten mislukt!", "Er is iets mis gegaan met het ophalen van de studenten. Probeer het zometeen nog een keer.", "OK")
        })
    }

    function getPostData() {
        if (currentMode === DateNavigatorMode.WEEK) {
            return {
                from: getDateForDayOfWeek(0).getTime(),
                until: moment(getDateForDayOfWeek(6)).set({
                    hour: 23,
                    minute: 59,
                    second: 59,
                    millisecond: 999
                }).toDate().getTime(),
            }
        } else {
            return {
                from: currentMoment.startOf('month').valueOf(),
                until: currentMoment.endOf('month').valueOf(),
            }
        }
    }


    function filterGrades() {
        if (pupils){
            const filteredGrades = pupils.data.map((grades) => grades.grade).sort((a, b) => (a.title > b.title ? 1 : -1)).filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.id === value.id && t.title === value.title
                    ))
            )
            const allOption = {id: null,'title': 'Alle'};
            setGrades([allOption, ...filteredGrades])
        }
    }


    function getDateForDayOfWeek(columnIndex, useCurrentTime = false) {
        let thisWeekMonday = moment().year(currentMoment.year()).week(currentMoment.week()).isoWeekday(1);

        // If useCurrentTime is false the time is set to midnight
        !useCurrentTime && (thisWeekMonday = thisWeekMonday.set({hour: 0, minute: 0, second: 0, millisecond: 0}));
        const daysOfWeek = [
            thisWeekMonday.subtract(2, 'days').toDate(),
            thisWeekMonday.add(1, 'days').toDate(),
            thisWeekMonday.add(1, 'days').toDate(),
            thisWeekMonday.add(1, 'days').toDate(),
            thisWeekMonday.add(1, 'days').toDate(),
            thisWeekMonday.add(1, 'days').toDate(),
            thisWeekMonday.add(1, 'days').toDate(),
        ]
        return daysOfWeek[columnIndex]
    }

    function goToScheduleScreen(element) {
        AppStorage.set(StorageKey.PUPIL_ID, element.id)
        AppStorage.set(StorageKey.PUPIL_TITLE, element.firstname + (element.surname ? (" " + element.surname) : ""))
        history.push("/")
    }
}

const DashboardRoot = styled.div`
    width: 100%;
    height: 100%;
    background: ${purpleLightest};
    position: relative;
    min-width: 0;
    overflow-y: ${props => props.overlayActive ? "auto" : "scroll"};
`;

const DashboardGrid = styled.div`
    display: grid;
    min-height: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 212px;
    grid-row-gap: 25px;
    grid-column-gap: 40px;
    padding-top: 20px;
    overflow-y: visible;
    
    @media screen and ${device.laptopL} {
        grid-template-columns: repeat(2, 1fr);
    }
    
    @media screen and ${device.laptop} {
        grid-template-columns: repeat(1, 1fr);
    }
    
    @media screen and ${device.tablet} {
        grid-template-columns: repeat(2, 1fr);
         padding-top: 20px;
    }
    
    @media screen and ${device.tabletS} {
        grid-template-columns: repeat(1, 1fr);
    }
`;

const DashboardContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 119.5px;
    margin-right: 119.5px;
    padding-top: 20px;
    padding-bottom: 30px;

    @media screen and ${device.laptopL} {
        margin-left: 40px;
        margin-right: 40px;
    }
    
    @media screen and ${device.tablet} {
        padding-top: 0px;
        margin-left: 20px;
        margin-right: 20px;
    }
`;

export default DashboardPupilsScreen;

