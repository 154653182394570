import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {nunito, purpleDark, white} from "../Theme";
import chevronLeftLogo from "../resources/images/chevron_left.svg";
import profileLogo from "../resources/images/pupil_profile.svg";
import {useHistory, useLocation} from "react-router-dom";
import {isInReadOnlyMode, RoutesWithoutNav} from "../App";
import AppStorage, {StorageKey} from "../util/AppStorage";
import {useGlobalState} from "../util/GlobalState";

function TopMenu() {
    const history = useHistory();
    const location = useLocation();
    const [isDashboard, setIsDashboard] = useGlobalState('isDashboard', false);
    const [isVisible, setVisible] = useState(false);

    useEffect(() => {
        if (window.location.href.indexOf("dashboard") > -1) {
            setIsDashboard(true)
        } else {
            setIsDashboard(false)
        }
    })

    useEffect(() => {
        if(RoutesWithoutNav.includes(location.pathname)){
            setVisible(false);
        } else {
            setVisible(isInReadOnlyMode());
        }
    }, [location.pathname]);

    return (
        <TopMenuWrapper visible={isVisible && !isDashboard}>
                <SelectPupilWrapper onClick={() => {
                    history.push("/dashboard")
                }}>
                    <Icon size={"14px"} src={chevronLeftLogo}></Icon>
                    <Text>Dashboard</Text>
                </SelectPupilWrapper>
            <SelectPupilWrapper>
                <Icon src={profileLogo}></Icon>
                <PupilText>{AppStorage.get(StorageKey.PUPIL_TITLE)}</PupilText>
            </SelectPupilWrapper>
        </TopMenuWrapper>
    )
}

const Text = styled.div`
    color: ${white};
    font-family: ${nunito};
    font-style: normal;
    font-size: 16px;
`;

const PupilText = styled.div`
    color: ${white};
    font-family: ${nunito};
    font-style: normal;
    font-size: 16px;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Icon = styled.img`
    color: white;
    height: ${props => props.src ? props.src : "20px"};
    width: ${props => props.src ? props.src : "20px"};
    src: ${props => props.src};
`;

const SelectPupilWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    gap: 7px;
    cursor: pointer;
`;
const TopMenuWrapper = styled.div`
    background: ${purpleDark};
    width: 100%;
    height: 43px;
    display: ${props => props.visible ? "flex" : "none"};
    flex-direction: row;
    justify-content: space-between;
    z-index: 1000;
    padding-left: 20px;
    padding-right: 20px;
`;

export default TopMenu;