import React, {useEffect, useState} from "react";
import BaseScreen from "../base/BaseScreen";
import {BasicGrid, SmallHeader, SmallHeaderTitle, sourceSansPro,} from "../../Theme";
import addIcon from "../../resources/images/ic-add.png";
import styled from "styled-components";
import {device} from "../../constants/breakpoints";
import ExamOverviewCard from "./ExamOverviewCard";
import Api from "../../util/Api";
import AppStorage, {StorageKey} from "../../util/AppStorage";
import moment from "moment";
import {useHistory} from "react-router-dom";
import 'moment/locale/nl';
import {Loader} from "../../components/Loader";
import {isInReadOnlyMode} from "../../App";
import {showNativeNavigation} from "../../AppInterface";

function ExamOverviewScreen(props) {
    const history = useHistory();
    const [exams, setExams] = useState([]);
    const [areExamsFetched, setExamsFetched] = useState(false);

    useEffect(() => {
        showNativeNavigation()
        Api.get(`pupils/${AppStorage.get(StorageKey.PUPIL_ID)}/exams`, (response) => {
            setExams(response.data)
            setExamsFetched(true);
        });
    }, []);

    const content = (
        <ExamOverViewRoot>

            {!areExamsFetched &&
                <Loader/>
            }

            <SmallHeader>
                <SmallHeaderTitle>Toetsweek</SmallHeaderTitle>
            </SmallHeader>

            {areExamsFetched &&
            <ExamGridWrapper>
                <BasicGrid>
                    {exams.sort((a, b) => {return a.date - b.date}).map((exam) => {
                        return  <ExamOverviewCard
                                    key={exam.id}
                                    exam={exam}
                                />
                    })}

                    {!isInReadOnlyMode() && <AddExamButton onClick={createNewExam}>
                        <AddExamButtonTextWrapper>
                            <AddExamButtonIcon src={addIcon} alt="toevoegen icoon"/>
                            <AddExamButtonText>
                                Toets toevoegen
                            </AddExamButtonText>
                        </AddExamButtonTextWrapper>
                    </AddExamButton>}
                </BasicGrid>
            </ExamGridWrapper>
            }
        </ExamOverViewRoot>
    );

    return (
        <BaseScreen
            activeMenuItem={"Toetsweek"}
            content={content}
        />
    )

    function createNewExam() {
        history.push('/toets');
    }
}


export function getFormattedDateFromTimestamp(timestamp) {
    const date = new Date(timestamp);
    let day = moment(date).format("dddd");
    return day.charAt(0).toUpperCase() + day.slice(1) + " " + moment(date).format("LL");
}


export const ExamOverViewRoot = styled.div`
    width: 100%;
    height: 100%;
    position: relative;  
`;

export const ExamGridWrapper = styled.div`
    margin: 0 auto;
    max-width: 1050px;
    padding: 40px;
    
    @media screen and ${device.laptop} {
        padding: 17px;
    }   
`;

export const AddExamButton = styled.a`
  position: relative;
  background: rgba(86, 89, 146, 0.05);
  border: 1px dashed rgba(86, 89, 146, 0.15);
  border-radius: 3px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 193px;
  &:hover {
    cursor: pointer;
  }

  @media screen and ${device.mobileL} {
        height: 57px;
  }   
`;

export const AddExamButtonTextWrapper = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
`;

export const AddExamButtonText = styled.span`
    font-family: ${sourceSansPro};
    font-weight: 400;
    font-size: 1.125em;
    color: #565992;
`;

export const AddExamButtonIcon = styled.img`
    width: 20px;
    height: 20px;
    align-self: end;
`;

export default ExamOverviewScreen;
