import React, {useEffect, useState} from "react";
import BaseScreen from "../base/BaseScreen";
import styled from "styled-components";
import {
    BasicGrid,
    black,
    nunito,
    purple,
    purpleLightest,
    red,
    SmallHeader,
    SmallHeaderTitle,
    sourceSansPro,
    white
} from "../../Theme";
import Api from "../../util/Api";
import {useHistory} from "react-router-dom";
import {device} from "../../constants/breakpoints";
import showExecutiveFunctionProgressPopup from "../../popups/showExecutiveFunctionProgressPopup";
import {Loader} from "../../components/Loader";
import rocketIcon from "../../resources/images/rocketIcon.png";
import AppStorage, {StorageKey} from "../../util/AppStorage";
import {showNativeNavigation} from "../../AppInterface";

function PlanOfActionOverview() {
    const [periods, setPeriods] = useState([]);
    const [lessonUpUrl, setLessonUpUrl] = useState(null);
    const history = useHistory();

    useEffect(() => {
        showNativeNavigation();
        getPeriods();
        getLessonUpUrlFromApi();
    }, []);

    const content = (
        <PlanOfActionOverviewRoot>

            {periods.length === 0 &&
            <Loader/>
            }

            <SmallHeader>
                <SmallHeaderTitle>Plan van aanpak</SmallHeaderTitle>
            </SmallHeader>

            {periods.length !== 0 &&
            <PlanOfActionOverviewWrapper>

                <GridWrapper>
                    <MobileProgressButton onClick={() => handleOnMobileProgressButtonClick()}>
                        <ProgressButtonText>Bekijk voortgangsoverzicht</ProgressButtonText>
                    </MobileProgressButton>

                    <BasicGrid
                        maxWidth={"100%"}
                        rowGap={"40px"}
                        columnGap={"40px"}
                        columnDivision={"1fr 1fr"}
                        laptopRowGap={"30px"}
                        laptopColumnGap={"30px"}
                        mobileRowGap={"16px"}
                        mobileColumnGap={"16px"}
                        mobileColumnDivision={"1fr 1fr"}
                    >
                        {periods.map((period, index) => {
                            return <PlanOfActionOverviewCard
                                key={period.id}
                                period={period}/>
                        })}
                    </BasicGrid>
                </GridWrapper>

                <PlanOfActionProgressCard periods={periods}/>

            </PlanOfActionOverviewWrapper>
            }
        </PlanOfActionOverviewRoot>
    );

    function PlanOfActionProgressCard(props) {

        return (
            <ProgressCard popup={props.popup}>
                <ProgressCardTitle>Voortgangsoverzicht</ProgressCardTitle>

                <ProgressListLabelWrapper>
                    <ProgressListLabel>Executieve functies</ProgressListLabel>
                    <ProgressListScoreLabelWrapper>
                        <ProgressListLabel>P1</ProgressListLabel>
                        <ProgressListLabel>P2</ProgressListLabel>
                        <ProgressListLabel>P3</ProgressListLabel>
                        <ProgressListLabel>P4</ProgressListLabel>
                    </ProgressListScoreLabelWrapper>
                </ProgressListLabelWrapper>

                <ProgressList>
                    {
                        (periods[0].scoresPerExecutiveFunction ?? []).map((ef, i) => {
                            return (
                                <ProgressListItem
                                    key={ef.title}
                                    index={i + 1}
                                    title={ef.title}
                                    scores={props.periods.map((p) => p.scoresPerExecutiveFunction[i].totalScore)}
                                />
                            )
                        })
                    }

                </ProgressList>
                {lessonUpUrl &&
                <a href={lessonUpUrl} target={"__blank"}><PracticeButton>Digitaal oefenen<RocketIcon src={rocketIcon}/></PracticeButton></a>}
            </ProgressCard>
        );
    }

    function PlanOfActionOverviewCard(props) {
        return (
            <PlanOfActionOverviewCardRoot onClick={() => navigateToPeriod()}>
                <OverviewCardImage src={props.period.imageUrl}/>
                <OverviewCardButton>
                    <OverViewCardButtonText>{props.period.title}</OverViewCardButtonText>
                </OverviewCardButton>
            </PlanOfActionOverviewCardRoot>
        );

        function navigateToPeriod() {
            if (props.period.scoresPerExecutiveFunction.filter(ef => ef.totalScore == null).length) {
                //If the user hasn't finished this period's questionnaire yet
                history.push("/plan-van-aanpak/" + props.period.id + "/vragenlijst/", {period: props.period})
            } else {
                history.push("/plan-van-aanpak/" + props.period.id, {period: props.period})
            }
        }
    }

    function ProgressListItem(props) {
        return (
            <ProgressListItemRoot>
                <ProgressListItemTitle>{props.index}. {props.title}</ProgressListItemTitle>
                <ScoreRow>
                    {props.scores.map((totalScore, index) => {
                        return <ScoreIndicatorIcon key={index} totalScore={totalScore}/>
                    })}
                </ScoreRow>
            </ProgressListItemRoot>
        );
    }

    return (
        <BaseScreen
            activeMenuItem={"Plan van aanpak"}
            content={content}
        />
    )

    function getPeriods() {
        const config = {
            params: {
                'context[personId]': AppStorage.get(StorageKey.PUPIL_ID) //call contains information generated on logged in member or context person
            }
        }
        Api.get("/periods", (response) => {
            setPeriods(response.data);
        }, null, null, null, config)
    }

    function handleOnMobileProgressButtonClick() {
        const content = (<PlanOfActionProgressCard popup={true} periods={periods}/>)
        showExecutiveFunctionProgressPopup(content);
    }

    function getLessonUpUrlFromApi() {
        Api.get('configuration', (response) => {
            setLessonUpUrl(response.data[0].lessonUpUrl)
        })
    }
}

export function ScoreIndicatorIcon(props) {
    return (
        <ScoreIndicatorRoot color={determineColor()}>
            {determineText()}
        </ScoreIndicatorRoot>
    );

    function determineColor() {
        if (!props.totalScore) {
            return purpleLightest;
        } else if (props.totalScore <= 10) {
            return "#DA0000";
        } else if (props.totalScore <= 15) {
            return "#FFB144";
        } else if (props.totalScore <= 20) {
            return "#0FE144";
        }
    }

    function determineText() {
        if (!props.totalScore) {
            return "";
        } else if (props.totalScore <= 10) {
            return "S";
        } else if (props.totalScore <= 15) {
            return "O";
        } else if (props.totalScore <= 20) {
            return "B";
        }
    }
}

export const PlanOfActionOverviewRoot = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    background: ${purpleLightest};
`;

export const PlanOfActionOverviewWrapper = styled.div`
    width: 100%;
    padding: 30px 30px 30px 72px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    
    @media screen and ${device.laptopL} {
        padding: 30px 17px;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    
    @media screen and ${device.mobileL} {
        padding: 17px;
    }
`;

export const PlanOfActionOverviewCardRoot = styled.div`
    width: 100%;
    height: 300px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    
    @media screen and ${device.tablet} {
        height: 220px;
    }
    
    @media screen and ${device.mobileL} {
        height: 190px;
    }
`;

export const OverviewCardImage = styled.img`
    width: 100%;
    min-height: 84%;
    object-fit: cover;
    object-position: center;
    margin-bottom: 5px;
    border: 1px solid rgba(86, 89, 146, 0.1);
    box-shadow: 0px 2px 0px rgba(86, 89, 146, 0.1);
    border-radius: 3px;
`;

export const OverviewCardButton = styled.div`
    width: 100%;
    height: 100%;
    background: ${purple};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 0px #4D2F78;
    border-radius: 3px;
`;

export const ProgressCard = styled.div`
    min-width: ${props => props.popup ? undefined : "395px"};
    min-height: ${props => props.popup ? undefined : "835px"};
    background: ${white};
    border: ${props => props.popup ? undefined : "1px solid rgba(86, 89, 146, 0.1);"};
    box-shadow: ${props => props.popup ? undefined : "0px 2px 0px rgba(86, 89, 146, 0.1);"} 
    border-radius: 3px;
    padding: ${props => props.popup ? undefined : "30px 40px 30px 40px"};
    margin-left: ${props => props.popup ? undefined : "72px"};
    
    @media screen and ${device.laptopL} {
        display: ${props => props.popup ? "block" : "none"};
    }
`;

export const OverViewCardButtonText = styled.div`
    font-family: ${sourceSansPro};
    font-weight: 600;
    font-size: 15px;
    color: ${white};
`;

export const ProgressCardTitle = styled.h2`
    font-family: ${sourceSansPro};
    font-weight: 600;
    font-size: 18px;
    color: ${red};
    margin: 0 0 35px 0;
    text-align: center;
`;

export const ProgressList = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ProgressListItemRoot = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    border-bottom: 1px solid rgba(86, 89, 146, 0.1);
    
    &:last-of-type {
        border-bottom: unset;
    }
`;

export const ProgressListItemTitle = styled.div`
    font-family: ${sourceSansPro};
    font-weight: 400;
    font-size: 16px;
    color: ${black};
`;

export const ScoreRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 9px;
`;

export const ScoreIndicatorRoot = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.color ? props.color : undefined};
    color: ${white};
    font-family: ${sourceSansPro};
    font-weight: 400;
    font-size: 16px;
    border-radius: 50%;
`;

export const ProgressListLabelWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px
`;

export const ProgressListScoreLabelWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 9px;
`;

export const ProgressListLabel = styled.div`
    font-family: ${nunito};
    font-weight: 400;
    font-size: 14px;
    color: ${black};
    opacity: 0.7;
    min-width: 20px;
    text-align: center;
`;

export const MobileProgressButton = styled.div`
    background: ${red};
    width: 100%;
    height: 41px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 0px #B7341D;
    border-radius: 3px;
    margin-bottom: 30px;
    cursor: pointer;
    display: none;
    
    @media screen and ${device.laptopL} {
        display: flex;
        margin-bottom: 16px;
    }
`;

export const ProgressButtonText = styled.div`
    font-family: ${sourceSansPro};
    font-weight: 600;
    font-size: 15px;
    color: ${white};
`;

export const PracticeButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background: #5E4187;
    font-family: ${sourceSansPro};
    font-size: 15px;
    font-weight: 600;
    color: #FFFFFF;
    width: 100%;
    cursor: pointer;
    height 35px;
    border: none;
    border-bottom: #4D2F78 3px;
    border-radius: 3px;
    &:hover {
        cursor: pointer;
    }
`;

export const RocketIcon = styled.img`
    src: ${props => props.src};
    width: 18px;    
    height: 18px;    
`
export const GridWrapper = styled.div`
    max-width: 900px;
    width: 100%;
`;


export default PlanOfActionOverview;

